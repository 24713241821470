<template>
    <div id="app">
        <nav-bar />
        <div id="scroll-container" data-scroll-container>
            <main>
                <router-view />
            </main>
            <footer-bar />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/NavBar.vue'
import FooterBar from '@/components/FooterBar.vue'

export default {
    computed: {
        ...mapGetters(['seo']),
    },

    metaInfo() {
        return {
            ...(this.seo.title && { title: this.seo.title }),
            ...(this.seo.title && { titleTemplate: '%s' }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    components: {
        NavBar,
        FooterBar
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';
</style>
