<template>
  <header class="navbar">
    <div class="left">
      <router-link :to="{ name: 'HomePage-' + $route.meta.lang }"><img src="@/assets/img/logo.svg" class="logo" alt="Logo Panoply"></router-link>
      <nav v-if="$route.name && $route.name.includes('Home')">
        <!-- <a href="/what-we-do" v-if="$route.meta.lang === 'en'">{{ $t('What We Do') }}</a> -->
        <!-- <a href="/fr/nos-services" v-if="$route.meta.lang === 'fr'">{{ $t('What We Do') }}</a> -->
        <a href="#work" data-scrollto="#work">{{ $t('Our Work') }}</a>
        <a href="#careers" data-scrollto="#careers">{{ $t('Our Culture') }}</a>
      </nav>
      <nav v-else>
        <!-- <a href="/what-we-do" v-if="$route.meta.lang === 'en'">{{ $t('What We Do') }}</a> -->
        <!-- <a href="/fr/nos-services" v-if="$route.meta.lang === 'fr'">{{ $t('What We Do') }}</a> -->
        <a href="/#a-work" @click="dbclickOnce">{{ $t('Our Work') }}</a>
        <a href="/#a-careers" @click="dbclickOnce">{{ $t('Our Culture') }}</a>
      </nav>
    </div>
    <div class="right">
        <router-link class="lang" :to="{ name: $route.name.replace('en', 'fr') }" v-if="$route.meta.lang === 'en'" title="Français">
            {{ $t('Fr') }}<span class="no-mobile">{{ $t('ançais') }}</span>
        </router-link>
        <router-link class="lang" :to="{ name: $route.name.replace('fr', 'en') }" v-if="$route.meta.lang === 'fr'" title="English">
            {{ $t('En') }}<span class="no-mobile">{{ $t('glish') }}</span>
        </router-link>
        <a href="#contact" data-scrollto="#contact" class="cta">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
        </a>
    </div>

    <div id="hamburger" @click.prevent="() => { mobileMenuOpen = !mobileMenuOpen }" :class="{ open: mobileMenuOpen }">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>

    <!-- Mobile Menu -->
    <div class="mobile-menu-wrap" :class="{ open: mobileMenuOpen }">
        <nav v-if="$route.name && $route.name.includes('Home')">
            <!-- <a href="/what-we-do" v-if="$route.meta.lang === 'en'">{{ $t('What We Do') }}</a> -->
            <!-- <a href="/fr/nos-services" v-if="$route.meta.lang === 'fr'">{{ $t('What We Do') }}</a> -->
            <a href="#work" data-scrollto="#work" @click.prevent.stop="closeAfterScroll()">{{ $t('Our Work') }}</a>
            <a href="#careers" data-scrollto="#careers" @click.prevent.stop="closeAfterScroll()">{{ $t('Our Culture') }}</a>
        </nav>
        <nav v-else>
            <!-- <a href="/what-we-do" v-if="$route.meta.lang === 'en'">{{ $t('What We Do') }}</a> -->
            <a href="/fr/nos-services" v-if="$route.meta.lang === 'fr'">{{ $t('What We Do') }}</a>
            <a href="/#a-work" @click="dbclickOnce">{{ $t('Our Work') }}</a>
            <a href="/#a-careers" @click="dbclickOnce">{{ $t('Our Culture') }}</a>
        </nav>

        <a href="#contact" data-scrollto="#contact" class="cta" @click="closeAfterScroll()">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
        </a>
        <div class="social">
          <a href="#"><img src="@/assets/img/icon-facebook.svg" alt="Facebook logo"></a>
          <a href="https://www.linkedin.com/company/panoply-media/?originalSubdomain=ca" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/icon-linkedin.svg" alt="Linkedin logo"></a>
        </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            mobileMenuOpen: false,
        }
    },
    computed: {
        ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
    },
    methods: {

        dbclickOnce(e) {
            if (!this.dbclick) {
                return
            }
            this.dbclick = false
            setTimeout(() => {
                e.target.click()
            }, 875)
        },

        closeAfterScroll() {
            setTimeout(() => {
                this.mobileMenuOpen = false
            }, 55)
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
