<template>
    <div class="svg-wrap">
        <div class="footer-svg-cut circles circles-back" data-scroll data-scroll-speed="-1">
            <svg class="inner-svg" viewBox="0 0 1920 1240" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="79.5" cy="536.516" r="124.5" fill="#6216EA"/>
                <circle cx="881.5" cy="575.508" r="163.5" fill="#6216EA"/>
            </svg>
        </div>
        <div class="footer-svg-cut waves" data-scroll data-scroll-speed="1">
            <svg class="inner-svg" viewBox="0 0 1920 1240" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1832.6 1255.68C1783.95 1265.26 1780.98 1326.09 1780.98 1326.09C1780.98 1326.09 1782.8 1387.68 1755.61 1395.18C1728.42 1402.67 1698.71 1402.41 1647.54 1355.53C1596.37 1308.65 1504.72 1226.02 1424.95 1228.72C1345.17 1231.42 1314.56 1318.75 1312.4 1324.77C1310.23 1330.8 1273.23 1476.34 1269.94 1498.04C1268.31 1507.03 1265.53 1515.8 1261.65 1524.19L1134.01 1504.82C1131.52 1498.68 1129.11 1492.32 1126.69 1485.82C1110.83 1443.24 1070.35 1334.08 985.175 1331.45C918.291 1330.51 919.89 1385.75 919.89 1385.75L906.995 1467.3C906.98 1468.32 906.877 1469.33 906.686 1470.33L-200.266 1302.39L-86.1256 550.068C-69.682 547.449 -47.9038 551.467 -22.1629 573.428C38.2394 624.868 32.8312 644.138 69.4903 623.267C106.149 602.396 96.3276 560.736 96.3276 560.736C96.3276 560.736 90.9986 506.02 134.522 505.228C178.045 504.436 213.615 547.92 213.615 547.92C213.615 547.92 284.315 629.474 331.66 595.434C379.006 561.393 347.091 510.016 347.091 510.016C347.091 510.016 319.352 447.664 374.301 436.975C430.034 421.115 474.216 440.502 474.216 440.502C474.216 440.502 552.203 490.398 584.928 438.273C617.654 386.148 680.27 357.584 683.906 358.136C687.542 358.687 741.903 343.732 766.403 419.294C790.902 494.856 769.937 592.142 769.937 592.142C769.937 592.142 766.896 652.995 826.969 649.425C887.042 645.855 931.635 580.74 931.635 580.74C931.635 580.74 1035.33 428.596 1125.69 421.581C1216.04 414.567 1265.95 486.589 1265.95 486.589C1265.95 486.589 1331.32 578.964 1401.94 554.798C1409.22 552.312 1416.19 549.262 1422.77 545.684L1422.56 545.652C1422.56 545.652 1472.69 522.834 1474.09 461.229C1475.49 399.624 1527.89 381.387 1552.55 402.048C1577.21 422.71 1599.58 465.033 1647.32 457.895C1695.05 450.757 1741.38 387.543 1751.78 371.347C1757.8 361.975 1784.77 341.892 1810.27 345.644L1810.63 345.699C1829.02 348.57 1846.59 363.943 1854.92 404.768C1874.05 498.683 1954.46 539.515 2024.21 553.689L1912.35 1290.98C1888.98 1270.81 1858.22 1250.65 1832.6 1255.68Z" fill="#141129"/>
            </svg>
        </div>
        <div class="footer-svg-cut circles circles-front" data-scroll data-scroll-speed="2">
            <svg class="inner-svg" viewBox="0 0 1920 1240" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="1512" cy="412.006" r="65" fill="#F7B8BA"/>
                <circle cx="1848.5" cy="687.5" r="111.5" fill="#6216EA"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'footer-svg-cut'
}
</script>

<style lang="scss" scoped>
    .svg-wrap {
        position: relative;
        margin-bottom: -22vw;
        margin-top: -10vw;
        background: #fff;

        .footer-svg-cut {
            position: relative;
            display: block;
            width: 105%;
            z-index: 5;
            background: transparent;

            .inner-svg {
                width: 100%;
            }
        }

        .circles {
            position: absolute;
            top: 0;
            left: 0;
        }

        .circles-back {
            top: 25px;
        }

        .circles-front {
            top: 25px;
        }
    }
</style>
