<template>
    <div class="footer-wrapper" :class="{ mauve: $route.meta.footer === 'mauve' }">
        <footer-svg-cut />
        <footer id="contact" class="main-footer max-padding" data-scroll data-scroll-speed="-0.5">
            <div class="wrap-title center" data-scroll data-inview="fadeInUp" data-delay="100">
                <h3 class="title" data-scroll data-scroll-speed="0.5">{{ $t("Ready to rethink your communication planning?") }}</h3>
            </div>
            <div class="offices">
              <p class="small-title" data-scroll data-inview="fadeIn" data-delay="200">{{ $t("Our offices") }}</p>
              <div class="office" data-scroll data-inview="fadeInUp" data-delay="300">
                <h4 class="medium-title">{{ $t("Montreal") }}</h4>
                <address class="regular-text">
                1435 rue St-Alexandre, Suite 700
                <br>
                Montreal, QC, H3A 2G4
                <!-- <br>
                {{ $t("Phone") }}: <a href="tel:15142246694">(+1) 514-224-6694</a> -->
                </address>
              </div>
              <div class="office" data-scroll data-inview="fadeInUp" data-delay="400">
                <h4 class="medium-title">Toronto</h4>
                <address class="regular-text">
                    214 King St. W, Suite 504
                    <br>
                    Toronto, ON, M5H 3S6
                    <!-- <br>
                    {{ $t("Phone") }}: <a href="tel:">(+1) 416-XXX-XXXX</a> -->
                </address>
              </div>
              <!-- <div class="office" data-scroll data-inview="fadeInUp" data-delay="500">
                <h4 class="medium-title">New York</h4>
                <address class="regular-text">
                  1435 St Alexander St,<br>Montreal, Québec, Canada<br>H3A 2G4
                </address>
              </div> -->
              <div class="office" data-scroll data-inview="fadeInUp" data-delay="600">
                <h4 class="medium-title">{{ $t("Virtually") }}</h4>
                <a href="mailto:admin@gopanoply.com">admin@gopanoply.com</a>
                <!-- <a href="tel:5142246694">514 224-6694</a> -->
                <div class="social">
                  <!-- <a href="#"><img src="@/assets/img/icon-facebook.svg" alt="Facebook logo"></a> -->
                  <a href="https://www.linkedin.com/company/panoply-media/?originalSubdomain=ca" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/icon-linkedin.svg" alt="Linkedin logo"></a>
                </div>
              </div>
            </div>
            <div class="footer-spacer"></div>
        </footer>
    </div>

</template>

<script>
import { mapState } from 'vuex'

import FooterSvgCut from '@/components/svg-cut/FooterSvgCut.vue'

export default {
    name: 'footer-bar',

    components: {
        FooterSvgCut,
    },

    computed: {
        ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
    }
}
</script>

<style lang="scss" scoped>

</style>
