import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/fr',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/instacart',
        name: 'Instacart-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Instacart.vue'),
        meta: { lang: 'en', json: 'instacart', footer: 'mauve' },
    },
    {
        path: '/fr/instacart',
        name: 'Instacart-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Instacart.vue'),
        meta: { lang: 'fr', json: 'instacart', footer: 'mauve' },
    },
    {
        path: '/gardasil',
        name: 'Gardasil-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Gardasil.vue'),
        meta: { lang: 'en', json: 'gardasil', footer: 'mauve' },
    },
    {
        path: '/fr/gardasil',
        name: 'Gardasil-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Gardasil.vue'),
        meta: { lang: 'fr', json: 'gardasil', footer: 'mauve' },
    },
    {
        path: '/notco',
        name: 'NotCo-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/NotCo.vue'),
        meta: { lang: 'en', json: 'notco', footer: 'mauve' },
    },
    {
        path: '/fr/notco',
        name: 'NotCo-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/NotCo.vue'),
        meta: { lang: 'fr', json: 'notco', footer: 'mauve' },
    },
    {
        path: '/what-we-do',
        name: 'Services-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Services.vue'),
        meta: { lang: 'en', json: 'services' },
    },
    {
        path: '/fr/nos-services',
        name: 'Services-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Services.vue'),
        meta: { lang: 'fr', json: 'services' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    x: 0,
                    y: 0,
                    behavior: 'smooth',
                })
            }, 555)
        })
    }
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    Store.dispatch('resetContent')

    let type = 'pages'
    // const { meta, params } = to
    const { meta } = to
    let slug = meta.json

    Store.dispatch('getContent', { lang: meta.lang, slug, type })
    next()
})

export default router
